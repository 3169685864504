import { Refresh } from "iconoir-react"

export default function ErrorFallback() {

  function reloadApp() {
    window.location.href = "/"
  }

  return (
    <div style={{ backgroundColor: "#F8F9FB" }}>
      <div className="container mx--auto px-4">
        <div className="flex flex-col items-center justify-center h-screen">
          <img style={{ maxWidth: "90%", width: 450 }} src={require("../../assets/error-image.png")} alt="Error" />
          <div className="mt-12 text-center flex flex-col items-center">
            <h3 className="mb-2 text-3xl font-bold">Oops ! Un problème est survenu</h3>
            <p className="text-lg">Veuillez réessayer ultérieurement.</p>
            <button
              className="mt-8 px-6 py-3 rounded-full bg-[#32548a] text-white flex flex-row gap-4"
              onClick={reloadApp}
            >
              <Refresh className="mr-2" fontSize="small" />
              Recharger la platforme
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
