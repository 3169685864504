import { Menu, Xmark } from "iconoir-react"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { urls } from "../../constants/urls"
import useFormStore from "../../features/form"
const Navbar = () => {

  const location = useLocation()

  const { price } = useFormStore()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <>
      <header className="sm:px-8 px-4 py-4 z-10 w-full sticky top-0 z-100 bg-[#32548a] text-white shadow-md">
        <nav className="flex justify-between items-center container mx-auto px-4">
          <Link to={urls.PROGRAM}>
            <img
              src={require("../../assets/afi-solo.png")}
              className="mr-3 h-6 sm:h-9"
              alt="Afi"
            />
          </Link>
          <div className="flex-1 flex justify-center items-center gap-10 max-lg:hidden">
            <Link to={urls.PROGRAM} className="p-4 text-center text-lg">Program</Link>
            <Link to={urls.BOOKING} className="p-4 text-center text-lg">Booking</Link>
            <Link to={urls.CONTACT} className="p-4 text-center text-lg">Contact</Link>
          </div>
          <div className="flex gap-2 text-lg leading-normal font-medium font-montserrat wide:mr-24">
            <p className="py-4 font-bold text-lg">
              {location.pathname === urls.BOOKING ? "Total: $" + price + " / " + (price * 10).toLocaleString() + " MAD" : ""}
            </p>
          </div>
          <div
            className="hidden max-lg:block cursor-pointer"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen)
            }}
          >
            <Menu className="text-2xl" />
          </div>
        </nav>
      </header>

      {isMenuOpen && (
        <div>
          <nav className="fixed top-0 right-0 left-0 bottom-0 lg:bottom-auto bg-slate-100 z-10">
            <div
              className="hidden max-lg:block fixed right-0  px-8 py-4 cursor-pointer"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen)
              }}
            >
              <Xmark className="text-2xl" />
            </div>
            <ul className="lg:hidden flex flex-col items-center justify-center h-full">
              <Link to={urls.PROGRAM} onClick={() => setIsMenuOpen(false)} className="p-4 text-center text-2xl">Program</Link>
              <Link to={urls.BOOKING} onClick={() => setIsMenuOpen(false)} className="p-4 text-center text-2xl">Booking</Link>
              <Link to={urls.CONTACT} onClick={() => setIsMenuOpen(false)} className="p-4 text-center text-2xl">Contact</Link>
            </ul>
          </nav>
        </div>
      )}
    </>
  )
}

export default Navbar

