import { Airplane, Check, CoffeeCup, Farm, Golf, HomeAlt, MapPin, PizzaSlice, Tree } from "iconoir-react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"

type Event = {
  icon: JSX.Element
  date: string
  title: string
  numberOfAttendees?: string
  location?: string
}

function TimelineElement(event: Event) {
  return (
    <VerticalTimelineElement
      icon={event.icon}
      date={event.date}
      dateClassName="text-black xl:text-white"
      contentStyle={{ background: "#fff", boxShadow: "0 0 10px 1px #00000011", borderRadius: 10, paddingBlock: 40 }}
      contentArrowStyle={{ borderRight: "7px solid  #fff" }}
      iconStyle={{ background: "#fff", color: "#32548a" }}
    >
      <h3 className="text-2xl font-bold text-[#32548a]">{event.title}</h3>
      <div className="flex flex-col gap-2">
        {/* {event.numberOfAttendees && <p className="m-0">Number of attendees: {event.numberOfAttendees}</p>} */}
        {event.location && (
          <div className="flex flex-row items-center gap-2 mt-4">
            <MapPin fontSize={14} />
            <p style={{ margin: 0 }}>{event.location}</p>
          </div>
        )}
      </div>
    </VerticalTimelineElement>
  )
}

export default function Program() {
  return (
    <div style={{ background: "linear-gradient(#32548a, #12346a)", minHeight: "100vh" }}>
      <div className="relative">
        <img src={require("../assets/banner.png")} className="w-full h-[500px] object-cover object-center" alt="AFI" />
        <div className="max-w-full absolute bottom-2 right-4 min-w-fit flex flex-col items-end">
          <img src={require("../assets/afi-big-white.png")} className="w-[300px]" alt="AFI" />
          <ul className="text-white text-xl flex flex-col md:flex-row gap-2 md:gap-6 flex-wrap justify-start items-end shadow-black">
            <li>Accommodations</li>
            <li>Airport Transfers</li>
            <li>Golf</li>
            <li>Tours</li>
          </ul>
        </div>
      </div>

      <div className="bg-white">
        <div className="px-4 py-32 container max-w-6xl mx-auto text-2xl">
          <p className="text-center leading-10">
            This site if for making hotel, airport transfer, golf and tour reservations for attendees of the 2024 AFI Convention. Detailed convention information and registration is available at <button className="text-blue-600 cursor-pointer p-0 bg-transparent border-none" onClick={() => window.location.href = "https://afievents.org/events/convention"}>afievents.org</button>. Only those registered for the convention will be allowed to attend the event.
          </p>
        </div>
      </div>

      <div id="program_section" className="container mx-auto px-4 pb-6 pt-20">
        <Tabs selectedTabClassName="bg-[#32548a] text-white rounded-">
          <TabList className="flex gap-4 justify-center flex-col text-center md:flex-row">
            <Tab className="rounded p-4 cursor-pointer font-medium text-white">May 2nd</Tab>
            <Tab className="rounded p-4 cursor-pointer font-medium text-white">May 3rd</Tab>
            <Tab className="rounded p-4 cursor-pointer font-medium text-white">May 4th</Tab>
            <Tab className="rounded p-4 cursor-pointer font-medium text-white">May 5th</Tab>
            <Tab className="rounded p-4 cursor-pointer font-medium text-white">May 6th</Tab>
          </TabList>
          <h2 className="uppercase font-bold text-white text-3xl text-center py-16">PROGRAM AT A GLANCE</h2>
          <TabPanel>
            <VerticalTimeline>
              <TimelineElement
                icon={<HomeAlt />}
                date="6:00 pm - 9:00 pm"
                title="Welcome reception Sponsored by Morocco Foodex"
                numberOfAttendees="300"
                location="KENZI CLUB AGDAL MEDINA"
              />
            </VerticalTimeline>
          </TabPanel>
          <TabPanel>
            <VerticalTimeline>
              <TimelineElement
                icon={<Check />}
                date="8:45 am - 10:00 am"
                title="Convention opening"
                numberOfAttendees="300"
                location="Conference center, KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<CoffeeCup />}
                date="10:00 am - 10:30 am"
                title="Coffee break"
                numberOfAttendees="300"
                location="KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<Check />}
                date="10:30 am - 12:30 pm"
                title="Various speakers"
                numberOfAttendees="300"
                location="Conference center, KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<PizzaSlice />}
                date="1:00 pm - 2:00 pm"
                title="Buffet Lunch"
                numberOfAttendees="300"
                location="Conference center garden, KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<Check />}
                date="2:30 pm - 5:00 pm"
                title="B2B meetings Sponsored by Morocco Foodex"
                numberOfAttendees="300"
                location="Meeting rooms, KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<Farm />}
                date="6:30 pm - 10:00 pm"
                title="Tour of the farm & dinner"
                numberOfAttendees="300"
                location="“Baron de Rothschild” Farm"
              />
            </VerticalTimeline>
          </TabPanel>
          <TabPanel>
            <VerticalTimeline>
              <TimelineElement
                icon={<Check />}
                date="8:30 am - 10:00 am"
                title="AFI General meeting"
                numberOfAttendees="300"
                location="Conference center, KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<CoffeeCup />}
                date="10:00 am - 10:30 am"
                title="Coffee break"
                numberOfAttendees="300"
                location="KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<Check />}
                date="10:30 am - 12:30 pm"
                title="Various speakers"
                numberOfAttendees="300"
                location="Conference center, KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<PizzaSlice />}
                date="1:00 pm - 2:00 pm"
                title="Buffet lunch"
                numberOfAttendees="300"
                location="Conference center garden, KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<Check />}
                date="2:00 pm - 5:00 pm"
                title="B2B meetings Sponsored by Morocco Foodex"
                numberOfAttendees="TBC"
                location="Meeting rooms, KENZI CLUB AGDAL MEDINA"
              />

              <TimelineElement
                icon={<PizzaSlice />}
                date="Evening"
                title="Dinner (Optional), Restaurants list provided"
                numberOfAttendees="TBC"
                location="Marrakech"
              />
            </VerticalTimeline>
          </TabPanel>
          <TabPanel>
            <VerticalTimeline>
              <TimelineElement
                icon={<Tree />}
                date="Morning"
                title="Guided tour of Marrakech’s top spots"
                numberOfAttendees="TBC"
                location="MARRAKECH"
              />

              <TimelineElement
                icon={<Golf />}
                date="Morning"
                title="Golf Outing"
                numberOfAttendees="TBC"
                location="The Montgomerie Marrakech"
              />

              <TimelineElement
                icon={<PizzaSlice />}
                date="Evening"
                title="Cocktail and Gala Dinner ceremony"
                numberOfAttendees="300"
                location="Beldi Country Club"
              />
            </VerticalTimeline>
          </TabPanel>
          <TabPanel>
            <VerticalTimeline>
              <TimelineElement
                icon={<Airplane />}
                date="Day"
                title="Departure day"
              />
            </VerticalTimeline>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}
