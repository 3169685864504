import request from "../config/request"

export type HotelCapacityResponse = {
  "hotel_name": string,
  "capacity_status": true, // true means available
  "available_rooms_number": number
}

export type HotelCapacityError = {
  detail: "400_wrong_login_or_password" | ""
}

export default async function hotelCapacity() {
  const response = await request({
    method: "GET",
    url: "/reservation/hotels-capacity-status/",
  })

  return response.data as HotelCapacityResponse[]
}
