type ButtonProps = {
  label: string
  onClick?: () => void
}

export default function Button(props: ButtonProps) {

  const className = () => {
    const classes = "bg-[#32548a] hover:bg-[#0c2478] font-bold text-white py-2 px-8 rounded-full"
    // const classes = "bg-[#fff] hover:bg-[#eee] text-[#32548a] font-bold py-2 px-8 rounded-full"
    return classes
  }

  return (
    <button className={className()} onClick={props.onClick}>{props.label}</button>
  )
}
