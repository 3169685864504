import { ArrowUp } from "iconoir-react"
import { useLocation } from "react-router-dom"
import { urls } from "../../constants/urls"

export default function Footer() {

  const location = useLocation()

  if (location.pathname !== urls.PROGRAM) return null

  return (
    <div className="fixed bottom-4 right-4 rounded-full text-[#32548a] border border-[#32548a] bg-white p-4 z-10 cursor-pointer shadow-xl">
      <a href="#program_section">
        <ArrowUp fontWeight={900} />
      </a>
    </div>
  )
}
