import request from "../config/request"

export type ActivityCapacityResponse = {
  "activity_name": string,
  "is_available": true, // true means available
  "available_places_number": number
}

export type ActivityCapacityError = {
  detail: "400_wrong_login_or_password" | ""
}

export default async function activityCapacity() {
  const response = await request({
    method: "GET",
    url: "/reservation/activities-capacity-status/",
  })

  return response.data as ActivityCapacityResponse[]
}
