import axios from "axios"

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000, // 10 seconds
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
})

export default request
