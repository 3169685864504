import { useEffect } from "react"
import validator from "validator"
import reserver from "../api/reserver"
import FormActivities from "../components/steps/FormActivities"
import FormHotel from "../components/steps/FormHotel"
import FormPayment from "../components/steps/FormPayment"
import FormPersonal from "../components/steps/FormPersonal"
import FormTransfer from "../components/steps/FormTransfer"
import Gateway from "../components/payment/Gateway"
import Button from "../components/ui/Button"
import useFormStore from "../features/form"
import { stripEmptyKeys } from "../utils"
import useReservation from "../hooks/useReservation"

export default function Booking() {

  const {
    step,
    nextStep,
    prevStep,
    data,
    setErrors,
    calculatePrice,
    gateway,
    setGateway,
    isFormSubmitted,
    setIsFormSubmitted
  } = useFormStore()

  const { paymentId, validated } = useReservation()

  useEffect(() => {
    calculatePrice()
  })

  const validateForm = () => {
    const gotoSteps = new Set<number>()
    let hasErrors = false

    if (step === 1) {
      if (validator.isEmpty(data.first_name)) {
        setErrors("first_name", "Required")
        gotoSteps.add(1)
        hasErrors = true
      }

      if (validator.isEmpty(data.last_name)) {
        setErrors("last_name", "Required")
        gotoSteps.add(1)
        hasErrors = true
      }

      if (!validator.isEmail(data.email)) {
        setErrors("email", "Invalid email")
        gotoSteps.add(1)
        hasErrors = true
      }

      if (validator.isEmpty(data.email)) {
        setErrors("email", "Required")
        gotoSteps.add(1)
        hasErrors = true
      }

      if (!validator.isMobilePhone(data.phone)) {
        setErrors("phone", "Invalid phone number")
        gotoSteps.add(1)
        hasErrors = true
      }

      if (validator.isEmpty(data.phone)) {
        setErrors("phone", "Required")
        gotoSteps.add(1)
        hasErrors = true
      }
    }

    if (step === 2) {
      if (validator.isEmpty(data.reservation_start_date)) {
        setErrors("reservation_start_date", "Required")
        gotoSteps.add(2)
        hasErrors = true
      }

      if (validator.isEmpty(data.reservation_end_date)) {
        setErrors("reservation_end_date", "Required")
        gotoSteps.add(2)
        hasErrors = true
      }

      if (validator.isEmpty(data.hotel)) {
        setErrors("hotel", "Required")
        gotoSteps.add(2)
        hasErrors = true
      }

      if (validator.isEmpty(data.type_room)) {
        setErrors("type_room", "Required")
        gotoSteps.add(2)
        hasErrors = true
      }
    }

    if (step === 3) {
      if (validator.isEmpty(data.transfer)) {
        setErrors("transfer", "Required")
        gotoSteps.add(3)
        hasErrors = true
      }

      // if (data.transfer === "Yes") {
      //   if (validator.isEmpty(data.arrival_airport)) {
      //     setErrors("arrival_airport", "Required")
      //     gotoSteps.add(3)
      //     hasErrors = true
      //   }

      //   if (validator.isEmpty(data.arrival_date)) {
      //     setErrors("arrival_date", "Required")
      //     gotoSteps.add(3)
      //     hasErrors = true
      //   }

      //   if (validator.isEmpty(data.arrival_time)) {
      //     setErrors("arrival_time", "Required")
      //     gotoSteps.add(3)
      //     hasErrors = true
      //   }

      //   if (validator.isEmpty(data.departure_airport)) {
      //     setErrors("departure_airport", "Required")
      //     gotoSteps.add(3)
      //     hasErrors = true
      //   }

      //   if (validator.isEmpty(data.departure_date)) {
      //     setErrors("departure_date", "Required")
      //     gotoSteps.add(3)
      //     hasErrors = true
      //   }

      //   if (validator.isEmpty(data.departure_time)) {
      //     setErrors("departure_time", "Required")
      //     gotoSteps.add(3)
      //     hasErrors = true
      //   }
      // }
    }

    if (step === 4) {
      if (validator.isEmpty(data.activity)) {
        setErrors("activity", "Required")
        gotoSteps.add(4)
        hasErrors = true
      }
    }

    if (step === 5) {
      if (validator.isEmpty(data.condition_accepted) || data.condition_accepted === "false") {
        setErrors("condition_accepted", "Required")
        hasErrors = true
      }
    }
    // if (gotoSteps.size) setStep(Array.from(gotoSteps).reduce((acc, step) => Math.min(acc, step), 1))
    return !hasErrors
  }

  const submitForm = () => {
    if (validateForm()) {
      try {
        const newData = { ...data }

        if (newData.hotel === "Kenzi club agdal medina") {
          newData.hotel = "1"
        } else if (newData.hotel === "Kenzi menara palace") {
          newData.hotel = "2"
        }

        if (newData.type_room.includes("single")) {
          newData.type_room = "single"
        } else if (newData.type_room.includes("double")) {
          newData.type_room = "double"
        }

        if (newData.activity.includes("No activity")) {
          newData.activity = "1"
        } else if (newData.activity.includes("Golf tournament")) {
          newData.activity = "2"
        } else if (newData.activity.includes("Guided tour of Marrakech")) {
          newData.activity = "3"
        }

        if (newData.arrival_airport.includes("Casablanca for 1")) {
          newData.arrival_airport = "Casablanca_single"
        } else if (newData.arrival_airport.includes("Casablanca for 2")) {
          newData.arrival_airport = "Casablanca_double"
        } else if (newData.arrival_airport.includes("Marrakech for 1")) {
          newData.arrival_airport = "Marrakech_single"
        } else if (newData.arrival_airport.includes("Marrakech for 2")) {
          newData.arrival_airport = "Marrakech_double"
        }

        if (newData.departure_airport.includes("Casablanca for 1")) {
          newData.departure_airport = "Casablanca_single"
        } else if (newData.departure_airport.includes("Casablanca for 2")) {
          newData.departure_airport = "Casablanca_double"
        } else if (newData.departure_airport.includes("Marrakech for 1")) {
          newData.departure_airport = "Marrakech_single"
        } else if (newData.departure_airport.includes("Marrakech for 2")) {
          newData.departure_airport = "Marrakech_double"
        }

        reserver(stripEmptyKeys(newData)).then(response => {
          setIsFormSubmitted(true)
          setGateway(response)
        }).catch(error => console.error(error))
      } catch (error) {
        console.error(error)
      }
    }
  }

  const next = () => {
    if (validateForm()) {
      nextStep()
    }
  }

  const paymentMsg = () => {
    if (validated === true) return "Your payment was successful."
    else if (validated === false) return "There has been a problem with your payment."
    else if (validated === null) "Your payment is still being processed."
  }

  const FormContent = () => {
    if (paymentId) {
      if (validated !== undefined) return (
        <div className="my-8 mx-auto w-full bg-white p-8 rounded-3xl shadow-lg">
          <div className="text-center mt-10">
            <h3 className="mb-2 text-3xl font-bold">{paymentMsg()}</h3>
            <p className="text-lg">You should receive an email with the details shortly. If you do not receive it, please contact us at <a href="mailto:contact@afimarrakech2024.com">contact@afimarrakech2024.com</a></p>
          </div>
        </div>
      )
      else return (
        <div className="my-8 mx-auto w-full bg-white p-8 rounded-3xl shadow-lg">
          <div className="text-center mt-10">
            <h3 className="mb-2 text-3xl font-bold">Loading payment details ...</h3>
          </div>
        </div>
      )
    }

    if (isFormSubmitted) {
      return (
        <div className="my-8 mx-auto w-full bg-white p-8 rounded-3xl shadow-lg">
          <div className="text-center mt-20">
            <h3 className="mb-2 text-3xl font-bold">The form has been submitted successfully</h3>
            <p className="text-lg">You will be redirected to the payment page...</p>
            {gateway && gateway.data.actionSLK && <Gateway />}
          </div>
        </div>
      )
    } else {
      return (
        <div className="my-8 mx-auto w-full bg-white p-8 rounded-3xl shadow-lg">
          {step === 1 && <FormPersonal />}
          {step === 2 && <FormHotel />}
          {step === 3 && <FormTransfer />}
          {step === 4 && <FormActivities />}
          {step === 5 && <FormPayment />}

          <div className="flex flex-col">
            <div className="flex justify-between gap-2 mt-16">
              {step === 1 ? <div></div> : <Button label="Previous" onClick={prevStep} />}
              {step < 5 && <Button label="Next" onClick={next} />}
              {step === 5 && <Button label="Procceed to payment" onClick={submitForm} /> }
            </div>
            {step === 5 && (
              <div className="flex flex-col items-center">
                <div style={{ margin: "20px", display: "flex", justifyContent:"center" }}>
                  <img src={require("../assets/logo_cmi.png")} className="h-10 m-2" alt="cmi" />
                  <img src={require("../assets/secure_code_logo.png")} className="h-10 m-2" alt="mastercard" />
                  <img src={require("../assets/tn_verified_by_visa.png")} className="h-10 m-2" alt="visa" />
                </div>
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const bg = require("../assets/banner.png")

  return (
    <div style={{ background: "linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5) ), url(" + bg + ")", backgroundSize: "cover", backgroundPosition: "center", minHeight: "100vh", backgroundRepeat: "no-repeat" }}>
      <div className="container mx-auto px-4 py-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="flex flex-col justify-center items-center">
            <img src={require("../assets/afi-big-white.png")} className="max-w-full w-[500px]" alt="AFI" />
          </div>
          {FormContent()}
        </div>
      </div>
    </div>
  )
}
