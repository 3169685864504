type SelectProps = {
  id: string
  label: string
  choices: string[]
  value: string
  onChange: (e: any) => void
  error?: string | null
}

export default function Select(props: SelectProps) {

  const className = () => {
    let classes = "border rounded-2xl bg-white text-sm rounded-lg block w-full p-2.5 py-3 appearance-none"

    if (props.error) {
      classes += " border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500"
    } else {
      classes += " border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500"
    }

    return classes
  }

  return (
    <div>
      <label htmlFor={props.id}>{props.label}</label>
      <select
        className={className()}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
      >
        <option value="" disabled>Select an option</option>
        {props.choices.map(choice => (
          <option key={choice} value={choice}>{choice}</option>
        ))}
      </select>
      <p className="text-red-500 mt-2">{props.error}</p>
    </div>
  )
}
