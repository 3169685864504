import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import useFormStore from "../../features/form"
import Select from "../ui/Select"
import Input from "../ui/Input"
import { useEffect, useState } from "react"
import hotelCapacity from "../../api/hotelCapacity"

export default function FormHotel() {

  const { data, setData, errors } = useFormStore()
  const [hotelChoices, setHotelChoices] = useState<string[]>([])

  useEffect(() => {
    hotelCapacity().then(res => {
      const hotelChoices = []

      const kenziClubAvailable = res.find((res: any) => res.hotel_name === "Kenzi club agdal medina")?.capacity_status || false
      const kenziMenaraAvailable = res.find((res: any) => res.hotel_name === "Kenzi menara palace")?.capacity_status || false

      if (kenziClubAvailable) {
        hotelChoices.push("Kenzi club agdal medina")
      }
      if (kenziMenaraAvailable) {
        hotelChoices.push("Kenzi menara palace")
      }

      setHotelChoices(hotelChoices)
    }).catch(() => {
      setHotelChoices(["Kenzi club agdal medina", "Kenzi menara palace"])
    })
  }, [])

  return (
    <div>
      <h2 className="text-3xl text-center font-bold mb-10 mt-3">Choose your hotel</h2>

      <div className="grid grid-cols-1 gap-3 mb-8">
        <Input
          id="reservation_start_date"
          label="Reservation start date*"
          type="date"
          value={data.reservation_start_date}
          onChange={(e) => setData("reservation_start_date", e.target.value)}
          error={errors.reservation_start_date}
        />

        <Input
          id="reservation_end_date"
          label="Reservation end date*"
          type="date"
          value={data.reservation_end_date}
          onChange={(e) => setData("reservation_end_date", e.target.value)}
          error={errors.reservation_end_date}
        />

        {hotelChoices.length > 0 && (
          <Select
            id="hotel_name"
            label="Hotel*"
            choices={hotelChoices}
            value={data.hotel}
            onChange={(e) => setData("hotel", e.target.value)}
            error={errors.hotel}
          />
        )}

        {data.hotel && (
          <Select
            id="type_room"
            label="Room type*"
            choices={[
              data.hotel === "Kenzi club agdal medina" ? "single ($330 / 3300,00 MAD)" : "single ($280 / 2800,00 MAD)",
              data.hotel === "Kenzi club agdal medina" ? "double ($380 / 3800,00 MAD)" : "double ($330 / 3300,00 MAD)"
            ]}
            value={data.type_room}
            onChange={(e) => setData("type_room", e.target.value)}
            error={errors.type_room}
          />
        )}
      </div>

      <div>
        {data.hotel === "Kenzi club agdal medina" && (
          <div className="flex flex-col border border-gray-300 bg-white rounded-xl">
            <Carousel infiniteLoop showThumbs={false} swipeable emulateTouch>
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_1.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_2.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_3.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_4.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_5.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_6.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_7.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_8.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_9.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_10.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_11.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_club_agdal_12.jpg")} />
            </Carousel>
            <div className="p-4">
              <h2 className="text-xl font-bold text-black">
                KENZI CLUB AGDAL MEDINA in All-inclusive (Breakfast buffet, lunch buffet, Diner buffet with soft drinks)
              </h2>
              <div className="text-gray-700 mt-4">
                <p>Get the celebrity treatment with world-class service at Kenzi Club Agdal Medina - All Inclusive (food and beverage)</p>
                <p>Located in the tourist center of Marrakech, this luxurious club hotel features 2 swimming pools, 2 tennis courts and a fitness center. The spacious rooms feature a balcony and a mini-bar.</p>
                <p>The Agdal Medina features a spa including a hammam, hot tub and massages are available upon request.</p>
                <p>Guest rooms are equipped with a TV with satellite channels. All rooms have a private bathroom including a hairdryer. Guests are welcomed with a bottle of water in the room. Free Wi-Fi access is available in public areas.</p>
              </div>
            </div>
          </div>
        )}

        {data.hotel === "Kenzi menara palace" && (
          <div className="flex flex-col border border-gray-300 bg-white rounded-xl">
            <Carousel infiniteLoop showThumbs={false} swipeable emulateTouch>
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_menara_palace_1.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_menara_palace_2.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_menara_palace_3.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_menara_palace_4.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_menara_palace_5.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_menara_palace_6.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_menara_palace_7.jpg")} />
              <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/kenzi_menara_palace_8.jpg")} />
            </Carousel>
            <div className="p-3">
              <h2 className="text-xl font-bold text-black">
                KENZI MENARA PALACE & RESORT in Bed & breakfast
              </h2>
              <div className="text-gray-700 mt-4">
                <p>Get the celebrity treatment with world-class service at Kenzi Menara Palace & Resort</p>
                <p>This luxury hotel is located in a 10-acre park in Marrakech, just 10 minutes driving from the Jamaâ El Fna Square. It offers free access to the outdoor swimming pool and the fitness center.</p>
                <p>Guest rooms are classically decorated using bright, Moroccan colors and feature a balcony with a view of the Atlas Mountains. Rooms are equipped with air-conditioning and free Wi-Fi access.</p>
              </div>
            </div>
          </div>
        )}

        {data.hotel === "" && (
          <div className="flex flex-col border border-gray-300 bg-white rounded-xl">
            <div className="w-full h-[300px] bg-gray-200 rounded-t-xl"></div>
            <div className="p-4">
              <h2 className="text-xl font-bold text-black">
                Please select a hotel to see the details
              </h2>
              <div className="text-gray-700 mt-4">
                <div className="w-full h-[35px] bg-gray-100 my-2"></div>
                <div className="w-full h-[35px] bg-gray-100 my-2"></div>
                <div className="w-[60%] h-[35px] bg-gray-100 my-2"></div>
              </div>
            </div>
          </div>
        )}

        <div className="text-black mt-4 text-sm">
          <p className="font-bold">* Cancellation policy:</p>
          <ul>
            <li>If canceled up to 90 days before the event, a 50% refund will be issued.</li>
            <li>Unfortunately, no refunds will be provided for cancellations made less than 90 days before the event.</li>
          </ul>
          <p className="font-bold mt-2">* How to cancel:</p>
          <p>Please notify us via email at contact@afimarrakech2024.com for all cancellations.</p>
        </div>
      </div>
    </div>
  )
}
