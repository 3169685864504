import React from "react"
import useFormStore from "../../features/form"

export default function Gateway() {

  const formRef = React.useRef<HTMLFormElement>(null)

  React.useEffect(() => {
    if (!formRef.current) return
    try {
      formRef.current.submit()
    } catch (err) {
      console.log(err)
    }
  }, [formRef.current])

  const { gateway } = useFormStore()

  if (!gateway) return null

  return (
    <form ref={formRef} name="pay_form" method="post" action={gateway?.data.actionSLK}>
      <input type="hidden" name="clientid" value={gateway?.data.clientid} />
      <input type="hidden" name="oid" value={gateway?.data.oid} />
      <input type="hidden" name="amount" value={gateway?.data.amount} />
      <input type="hidden" name="okUrl" value={gateway?.data.okUrl} />
      <input type="hidden" name="failUrl" value={gateway?.data.failUrl} />
      <input type="hidden" name="callbackUrl" value={gateway?.data.callbackUrl} />
      <input type="hidden" name="TranType" value={gateway?.data.trantype} />
      <input type="hidden" name="shopurl" value={gateway?.data.shopurl} />
      <input type="hidden" name="currency" value={gateway?.data.currency} />
      <input type="hidden" name="rnd" value={gateway?.data.rnd} />
      <input type="hidden" name="storetype" value={gateway?.data.storetype} />
      <input type="hidden" name="hashAlgorithm" value={gateway?.data.hashAlgorithm} />
      <input type="hidden" name="lang" value={gateway?.data.lang} />
      <input type="hidden" name="refreshtime" value={gateway?.data.refreshtime} />
      <input type="hidden" name="BillToName" value={gateway?.data.BillToName} />
      <input type="hidden" name="BillToStreet1" value={gateway?.data.BillToStreet1} />
      <input type="hidden" name="BillToCity" value={gateway?.data.BillToCity} />
      <input type="hidden" name="BillToPostalCode" value={gateway?.data.BillToPostalCode} />
      <input type="hidden" name="BillToCountry" value={gateway?.data.BillToCountry} />
      <input type="hidden" name="email" value={gateway?.data.email} />
      <input type="hidden" name="tel" value={gateway?.data.tel} />
      <input type="hidden" name="encoding" value={gateway?.data.encoding} />
      <input type="hidden" name="amountCur" value={gateway?.data.amountCur} />
      <input type="hidden" name="symbolCur" value={gateway?.data.symbolCur} />
      <input type="hidden" name="hash" value={gateway?.data.hash} />
    </form>
  )
}
