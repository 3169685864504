import request from "../config/request"
import { Form } from "../features/form"

export type ReserverRequest = Form["data"]

export type ReserverResponse = {
  message: string
  data: {
    actionSLK: string
    BillToName: string
    BillToStreet1: string
    BillToCity: string
    BillToPostalCode: string
    BillToCountry: string
    clientid: string
    storetype: string
    trantype: string
    currency: string
    okUrl: string
    failUrl: string
    shopurl: string
    callbackUrl: string
    lang: string
    rnd: string
    hashAlgorithm: string
    storeKey: string
    refreshtime: string
    encoding: string
    oid: string
    email: string
    tel: string
    amount: string
    amountCur: string
    symbolCur: string
    hash: string
  }
}

export type ReserverError = {
  detail: string
}

export default async function reserver(params: ReserverRequest) {
  const response = await request({
    method: "POST",
    url: "/reservation/reserver/",
    data: params
  })

  return response.data as ReserverResponse
}
