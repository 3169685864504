import useFormStore from "../../features/form"
import Input from "../ui/Input"
import Select from "../ui/Select"

export default function FormTransfer() {

  const { data, setData, errors } = useFormStore()

  return (
    <div>
      <h2 className="text-3xl text-center font-bold mb-10 mt-3">Transfer informations</h2>


      <div className="grid grid-cols-1 gap-4">
        <div>
          <Select
            id="gender"
            label="Do you want to book a transfer?*"
            choices={["Yes", "No"]}
            value={data.transfer}
            onChange={(e) => setData("transfer", e.target.value)}
            error={errors.transfer}
          />

          <p className="text-gray-500 text-sm">All transfers will be conducted using private minivans or minibuses. Upon arrival, guests will be picked up from their chosen airport and transported to the selected hotel. Upon departure, guests will be picked up from their hotel and taken to the selected airport.</p>
        </div>
      </div>

      {data.transfer === "Yes" && (
        <>
          <h2 className="text-2xl text-center font-bold mb-10 mt-8">Arrival informations</h2>

          <div className="grid grid-cols-1 gap-4">
            <Select
              id="arrival_airport"
              label="Arrival airport"
              choices={[
                "Mohamed V (CMN) - Casablanca for 1 passenger ($180 / 1800,00 MAD)",
                "Mohamed V (CMN) - Casablanca for 2 passengers only when double room booked ($240 / 2400,00 MAD)",
                "Menara (RAK) - Marrakech for 1 passenger ($45 / 450,00 MAD)",
                "Menara (RAK) - Marrakech for 2 passengers when double room booked ($60 / 600,00 MAD)",
              ]}
              value={data.arrival_airport}
              onChange={(e) => setData("arrival_airport", e.target.value)}
              error={errors.arrival_airport}
            />

            <Input
              id="arrivale_date"
              label="Arrival date"
              type="date"
              value={data.arrival_date}
              onChange={(e) => setData("arrival_date", e.target.value)}
              error={errors.arrival_date}
            />

            <Input
              id="arrival_time"
              label="Arrival time"
              type="time"
              value={data.arrival_time}
              onChange={(e) => setData("arrival_time", e.target.value)}
              error={errors.arrival_time}
            />

            <Input
              id="arrival_flight_number"
              label="Arrival flight number"
              type="text"
              value={data.arrival_flight_number}
              onChange={(e) => {setData("arrival_flight_number", e.target.value), document.body.style.overflow= "hidden"}}
              onBlur={() => {
                document.body.style.overflow= ""
              }}
              error={errors.arrival_flight_number}
            />
          </div>

          <h2 className="text-2xl text-center font-bold mb-10 mt-10">Departure informations</h2>

          <div className="grid grid-cols-1 gap-4">
            <Select
              id="departure_airport"
              label="Departure airport"
              choices={[
                "Mohamed V (CMN) - Casablanca for 1 passenger ($180 / 1800,00 MAD)",
                "Mohamed V (CMN) - Casablanca for 2 passengers only when double room booked ($240 / 2400,00 MAD)",
                "Menara (RAK) - Marrakech for 1 passenger ($45 / 450,00 MAD)",
                "Menara (RAK) - Marrakech for 2 passengers when double room booked ($60 / 600,00 MAD)",
              ]}
              value={data.departure_airport}
              onChange={(e) => setData("departure_airport", e.target.value)}
              error={errors.departure_airport}
            />

            <Input
              id="departuree_date"
              label="Departure date"
              type="date"
              value={data.departure_date}
              onChange={(e) => setData("departure_date", e.target.value)}
              error={errors.departure_date}
            />

            <Input
              id="departure_time"
              label="Departure time"
              type="time"
              value={data.departure_time}
              onChange={(e) => setData("departure_time", e.target.value)}
              error={errors.departure_time}
            />

            <Input
              id="departure_flight_number"
              label="Departure flight number"
              type="text"
              value={data.departure_flight_number}
              onChange={(e) => {setData("departure_flight_number", e.target.value), document.body.style.overflow= "hidden"}}
              onBlur={() => {
                document.body.style.overflow= ""
              }}
              error={errors.departure_flight_number}
            />
          </div>
        </>
      )}
    </div>
  )
}
