import useFormStore from "../../features/form"
import Input from "../ui/Input"

export default function FormPersonal() {

  const { data, setData, errors } = useFormStore()

  return (
    <div>
      <h2 className="text-3xl text-center font-bold mb-10 mt-3">Personal informations</h2>

      <div className="grid grid-cols-1 gap-5">
        <Input
          id="first_name"
          label="First name*"
          type="text"
          value={data.first_name}
          onChange={(e) => {setData("first_name", e.target.value), document.body.style.overflow= "hidden"}}
          onBlur={() => {
            document.body.style.overflow= ""
          }}
          error={errors.first_name}
        />

        <Input
          id="last_name"
          label="Last name*"
          type="text"
          value={data.last_name}
          onChange={(e) => {setData("last_name", e.target.value), document.body.style.overflow= "hidden"}}
          onBlur={() => {
            document.body.style.overflow= ""
          }}
          error={errors.last_name}
        />

        <Input
          id="email"
          label="Email*"
          type="email"
          value={data.email}
          onChange={(e) => {setData("email", e.target.value), document.body.style.overflow= "hidden"}}
          onBlur={() => {
            document.body.style.overflow= ""
          }}
          error={errors.email}
        />

        <Input
          id="phone"
          label="Phone*"
          type="tel"
          value={data.phone}
          onChange={(e) => {setData("phone", e.target.value), document.body.style.overflow= "hidden"}}
          onBlur={() => {
            document.body.style.overflow= ""
          }}
          error={errors.phone}
        />

        <Input
          id="passport"
          label="Passport"
          type="text"
          value={data.passport}
          onChange={(e) => {setData("passport", e.target.value), document.body.style.overflow= "hidden"}}
          onBlur={() => {
            document.body.style.overflow= ""
          }}
          error={errors.passport}
        />
      </div>
    </div>
  )
}
