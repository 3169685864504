import useFormStore from "../../features/form"

export default function FormPayment() {

  const { data, setData, errors, price } = useFormStore()

  const handleConditionClick = () => {
    const pdfUrl = process.env.PUBLIC_URL + "Terms_of_online_sales.pdf"
    window.open(pdfUrl, "_blank")
  }

  return (
    <>
      <div>
        <h2 className="text-3xl text-center font-bold mb-10 mt-3">Summary</h2>

        <ul className="flex flex-col gap-3">
          <h3 className="mt-6 font-bold text-2xl">Personal information</h3>
          {data.first_name && <li className="text-md flex flex-row justify-between"><strong>First name:</strong> <span>{data.first_name}</span></li>}
          {data.last_name && <li className="text-md flex flex-row justify-between"><strong>Last name:</strong> <span>{data.last_name}</span></li>}
          {data.email && <li className="text-md flex flex-row justify-between"><strong>Email:</strong> <span>{data.email}</span></li>}
          {data.phone && <li className="text-md flex flex-row justify-between"><strong>Phone:</strong> <span>{data.phone}</span></li>}
          {data.passport && <li className="text-md flex flex-row justify-between"><strong>Passport:</strong> <span>{data.passport}</span></li>}

          <h3 className="mt-6 font-bold text-2xl">Reservation</h3>
          {data.reservation_start_date && <li className="text-md flex flex-row justify-between"><strong>Reservation start date:</strong> <span>{data.reservation_start_date}</span></li>}
          {data.reservation_end_date && <li className="text-md flex flex-row justify-between"><strong>Reservation end date:</strong> <span>{data.reservation_end_date}</span></li>}
          {data.hotel && <li className="text-md flex flex-row justify-between"><strong>Hotel:</strong> <span>{data.hotel}</span></li>}
          {data.type_room && <li className="text-md flex flex-row justify-between"><strong>Type room:</strong> <span>{data.type_room}</span></li>}

          <h3 className="mt-6 font-bold text-2xl">Transfer</h3>
          {data.transfer && <li className="text-md flex flex-row justify-between"><strong>Transfer:</strong> <span>{data.transfer}</span></li>}
          {data.arrival_airport && <li className="text-md flex flex-row justify-between"><strong>Arrival airport:</strong> <span>{data.arrival_airport}</span></li>}
          {data.arrival_date && <li className="text-md flex flex-row justify-between"><strong>Arrival date:</strong> <span>{data.arrival_date}</span></li>}
          {data.arrival_time && <li className="text-md flex flex-row justify-between"><strong>Arrival time:</strong> <span>{data.arrival_time}</span></li>}
          {data.arrival_flight_number && <li className="text-md flex flex-row justify-between"><strong>Arrival flight number:</strong> <span>{data.arrival_flight_number}</span></li>}
          {data.departure_airport && <li className="text-md flex flex-row justify-between"><strong>Departure airport:</strong> <span>{data.departure_airport}</span></li>}
          {data.departure_date && <li className="text-md flex flex-row justify-between"><strong>Departure date:</strong> <span>{data.departure_date}</span></li>}
          {data.departure_time && <li className="text-md flex flex-row justify-between"><strong>Departure time:</strong> <span>{data.departure_time}</span></li>}
          {data.departure_flight_number && <li className="text-md flex flex-row justify-between"><strong>Departure flight number:</strong> <span>{data.departure_flight_number}</span></li>}

          <h3 className="mt-6 font-bold text-2xl">Activity</h3>
          {data.activity && <li className="text-md flex flex-row justify-between"><strong>Activity:</strong> <span>{data.activity}</span></li>}
          {data.handicap_index && <li className="text-md flex flex-row justify-between"><strong>Handicap index:</strong> <span>{data.handicap_index}</span></li>}
          {data.gender && <li className="text-md flex flex-row justify-between"><strong>Gender:</strong> <span>{data.gender}</span></li>}
          {data.own_golf_equipment && <li className="text-md flex flex-row justify-between"><strong>Own golf equipment:</strong> <span>{data.own_golf_equipment}</span></li>}
          {data.golf_bag && <li className="text-md flex flex-row justify-between"><strong>Golf bag:</strong> <span>{data.golf_bag}</span></li>}
          {data.handed && <li className="text-md flex flex-row justify-between"><strong>Handed:</strong> <span>{data.handed}</span></li>}
          {data.gloves && <li className="text-md flex flex-row justify-between"><strong>Gloves:</strong> <span>{data.gloves}</span></li>}
          {data.golf_transport && <li className="text-md flex flex-row justify-between"><strong>Golf transport:</strong> <span>{data.golf_transport}</span></li>}

          <h3 className="mt-6 font-bold text-2xl">Total</h3>
          <li className="text-md flex flex-row justify-between"><strong>Total:</strong> <span>
            {"$" + price + " / " + (price * 10).toLocaleString() + " MAD"}
          </span></li>
        </ul>

        <p className="text-gray-500 text-sm">Prices in dollars are approximate and for information only. The amount charged will vary according to the exchange rate at the transaction date.</p>

        <label className="flex flex-col mt-6">
          <div>
            <input
              type="checkbox"
              checked={data.condition_accepted === "true"}
              onChange={(e) => {setData("condition_accepted", e.target.checked.toString()), document.body.style.overflow= "hidden"}}
              onBlur={() => {
                document.body.style.overflow= ""
              }}
            />
            <span className="text-base m-2">
              I accept the <button className="text-blue-600 cursor-pointer p-0 bg-transparent" onClick={handleConditionClick}>general terms and conditions</button>
            </span>
          </div>
          <p className="text-red-500 mt-2">{errors.condition_accepted}</p>
        </label>
      </div>
    </>
  )
}
