type InputProps = {
  id: string
  label: string
  type: "text" | "date" | "number" | "email" | "password" | "tel" | "time"
  value: string
  onChange: (e: any) => void
  onBlur?: (e: any) => void
  error?: string | null
}

export default function Input(props: InputProps) {

  const className = () => {
    let classes = "border rounded-2xl bg-white text-sm rounded-lg block w-full p-2.5 py-3 appearance-none"

    if (props.error) {
      classes += " border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500"
    } else {
      classes += " border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500"
    }

    return classes
  }

  return (
    <div>
      <label htmlFor={props.id} className="text-lg">{props.label}</label>
      <input
        className={className()}
        id={props.id}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        {...props.type === "number" ? { min: 0 } : {}}
        {...props.type === "date" ? { min: "2024-05-01", max: "2024-05-06" } : {}}
      />
      <p className="text-red-500 mt-2">{props.error}</p>
    </div>
  )
}
