import React from "react"
import { useSearchParams } from "react-router-dom"
import reservation from "../api/reservation"

export default function useReservation() {

  const [searchParams] = useSearchParams()
  const paymentId = searchParams.get("id")

  const [validated, setValidated] = React.useState<boolean | null | undefined>(undefined)

  React.useEffect(() => {
    if (!paymentId) return
    reservation({ id: paymentId }).then(res => {
      setValidated(res.validated)
      return
    }).catch(err => {
      setValidated(false)
      console.log(err)
    })
  }, [paymentId])

  return {
    paymentId,
    validated
  }
}
