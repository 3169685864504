export default function getNumberOfDays(startDate: string, endDate: string): number {
  const arrivalDate = new Date(startDate)
  const departureDate = new Date(endDate)
  const numberOfNights = Math.round(departureDate.getTime() - arrivalDate.getTime()) / (1000 * 60 * 60 * 24)

  return numberOfNights
}

export function stripEmptyKeys<Type>(params: Type | undefined) {
  const paramsClone = { ...params }

  Object.keys(paramsClone).forEach(key => {
    if (
      paramsClone[key as keyof typeof params] === ""
      || paramsClone[key as keyof typeof params] === undefined
      || paramsClone[key as keyof typeof params] === null
    ) {
      delete paramsClone[key as keyof typeof params]
    }
  })

  return paramsClone as Type
}
