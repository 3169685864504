import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import getNumberOfDays from "../utils"
import { ReserverResponse } from "../api/reserver"

export type Form = {
  step: number
  nextStep: () => void
  prevStep: () => void
  setStep: (step: number) => void

  price: number
  calculatePrice: () => void

  data: {
    first_name: string
    last_name: string
    email: string
    phone: string
    passport: string

    reservation_start_date: string
    reservation_end_date: string
    hotel: string
    type_room: string

    transfer: string
    arrival_airport: string
    arrival_date: string
    arrival_time: string
    arrival_flight_number: string
    departure_airport: string
    departure_date: string
    departure_time: string
    departure_flight_number: string

    activity: string
    handicap_index: string
    gender: string
    own_golf_equipment: string
    golf_bag: string
    handed: string
    gloves: string
    golf_transport: string

    condition_accepted: string
  }
  setData: (key: keyof Form["data"], value: Form["data"][keyof Form["data"]]) => void

  errors: {
    first_name: string | null
    last_name: string | null
    email: string | null
    phone: string | null
    passport: string | null

    reservation_start_date: string | null
    reservation_end_date: string | null
    hotel: string | null
    type_room: string | null

    transfer: string | null
    arrival_airport: string | null
    arrival_date: string | null
    arrival_time: string | null
    arrival_flight_number: string | null
    departure_airport: string | null
    departure_date: string | null
    departure_time: string | null
    departure_flight_number: string | null

    activity: string | null
    handicap_index: string | null
    gender: string | null
    own_golf_equipment: string | null
    golf_bag: string | null
    handed: string | null
    gloves: string | null
    golf_transport: string | null

    condition_accepted: string | null
  }
  setErrors: (key: keyof Form["errors"], value: Form["errors"][keyof Form["errors"]]) => void

  gateway: ReserverResponse | null
  setGateway: (gateway: ReserverResponse | null) => void

  isFormSubmitted: boolean
  setIsFormSubmitted: (isFormSubmitted: boolean) => void
}

const initialData = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  passport: "",

  reservation_start_date: "",
  reservation_end_date: "",
  hotel: "",
  type_room: "",

  transfer: "",
  arrival_airport: "",
  arrival_date: "",
  arrival_time: "",
  arrival_flight_number: "",
  departure_airport: "",
  departure_date: "",
  departure_time: "",
  departure_flight_number: "",

  activity: "",
  handicap_index: "",
  gender: "",
  own_golf_equipment: "",
  golf_bag: "",
  handed: "",
  gloves: "",
  golf_transport: "",

  condition_accepted: ""
}

const initialErrors = {
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  passport: null,

  reservation_start_date: null,
  reservation_end_date: null,
  hotel: null,
  type_room: null,

  transfer: null,
  arrival_airport: null,
  arrival_date: null,
  arrival_time: null,
  arrival_flight_number: null,
  departure_airport: null,
  departure_date: null,
  departure_time: null,
  departure_flight_number: null,

  activity: null,
  handicap_index: null,
  gender: null,
  own_golf_equipment: null,
  golf_bag: null,
  handed: null,
  gloves: null,
  golf_transport: null,

  condition_accepted: null
}

const useFormStore = create<Form>()(immer(devtools((set) => ({
  step: 1,

  nextStep: () => {
    set(state => {
      if (state.step < 5) {
        return { step: state.step + 1 }
      }
    })
  },

  prevStep: () => {
    set(state => {
      if (state.step > 1) {
        return { step: state.step - 1 }
      }
    })
  },

  setStep: (newStep) => {
    set(state => {
      if (newStep >= 1 && newStep <= 5) {
        state.step = newStep
      }
    })
  },

  price: 0,

  calculatePrice: () => {
    set(state => {
      let price = 0

      const numberOfDays = getNumberOfDays(state.data.reservation_start_date, state.data.reservation_end_date)

      if (state.data.hotel === "Kenzi club agdal medina" && state.data.type_room.includes("single")) {
        price += 330 * numberOfDays
      } else if (state.data.hotel === "Kenzi club agdal medina" && state.data.type_room.includes("double")) {
        price += 380 * numberOfDays
      } else if (state.data.hotel === "Kenzi menara palace" && state.data.type_room.includes("single")) {
        price += 280 * numberOfDays
      } else if (state.data.hotel === "Kenzi menara palace" && state.data.type_room.includes("double")) {
        price += 330 * numberOfDays
      }

      if (state.data.transfer === "Yes") {
        let transferCost = 0

        if (state.data.arrival_airport.includes("Casablanca for 1")) {
          transferCost += 180
        } else if (state.data.arrival_airport.includes("Casablanca for 2")) {
          transferCost += 240
        } else if (state.data.arrival_airport.includes("Marrakech for 1")) {
          transferCost += 45
        } else if (state.data.arrival_airport.includes("Marrakech for 2")) {
          transferCost += 60
        }

        if (state.data.departure_airport.includes("Casablanca for 1")) {
          transferCost += 180
        } else if (state.data.departure_airport.includes("Casablanca for 2")) {
          transferCost += 240
        } else if (state.data.departure_airport.includes("Marrakech for 1")) {
          transferCost += 45
        } else if (state.data.departure_airport.includes("Marrakech for 2")) {
          transferCost += 60
        }

        price += transferCost
      }

      if (state.data.activity.includes("Golf tournament")) {
        price += 280
      } else if (state.data.activity.includes("Guided tour of Marrakech")) {
        price += 70
      }

      if (price < 0) price = 0
      state.price = price
    })
  },

  data: initialData,

  setData: (key, value) => {
    set(state => {
      state.errors[key] = null
      state.data[key] = value
    })
  },

  errors: initialErrors,

  setErrors: (key, value) => {
    set(state => {
      state.errors[key] = value
    })
  },

  gateway: null,

  setGateway: (gateway) => {
    set(state => {
      state.gateway = gateway
    })
  },

  isFormSubmitted: false,

  setIsFormSubmitted: (value) => {
    set(state => {
      state.isFormSubmitted = value
    })
  }
}))))

export default useFormStore
