import { ErrorBoundary } from "@sentry/react"
import { Route, Routes } from "react-router-dom"
import "./App.css"
import ErrorFallback from "./components/commun/ErrorFallback"
import Layout from "./components/commun/Layout"
import { urls } from "./constants/urls"
import Booking from "./pages/Booking"
import Home from "./pages/Contact"
import Program from "./pages/Program"

function App() {
  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path={urls.PROGRAM} element={<Program />} />
          <Route path={urls.BOOKING} element={<Booking />} />
          <Route path={urls.CONTACT} element={<Home />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  )
}

export default App
