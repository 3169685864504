import "react-accessible-accordion/dist/fancy-example.css"
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion"

type AccElement = {
  title: string
  content: JSX.Element
}

function AccordionElement(element: AccElement) {
  return (
    <AccordionItem className="mb-6">
      <AccordionItemHeading>
        <AccordionItemButton style={{ background: "transparent", borderBottom: "1px solid #00000011", color: "#000", fontWeight: "600", fontSize: "28px" }}>
          {element.title}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel style={{ color: "#333", fontSize: "22px" }}>
        {element.content}
      </AccordionItemPanel>
    </AccordionItem>
  )
}

export default function Contact() {
  return (
    <>
      <div className="bg-white py-10">
        <div className="container mx-auto px-4 flex flex-row justify-center">
          <img src={require("../assets/afi-big.png")} className="max-w-full w-[500px]" alt="AFI" />
        </div>
      </div>

      <div className="container max-w-6xl mx-auto px-4 py-20">
        <h2 className="text-5xl text-center mb-8">FAQ</h2>
        <Accordion allowMultipleExpanded allowZeroExpanded style={{ border: "none" }}>
          <AccordionElement
            title="Currency in Morocco"
            content={
              <div>
                <p>The official currency unit of Morocco is the Moroccan dirham (DH) which is abbreviated (MAD). 1 USD = 10 MAD</p>
                <p><strong>Bills:</strong> Available bills are 20-50-100-200 DH</p>
                <p><strong>Coins:</strong> The coins in use are 0.50-1-2-5 and 10 DH</p>
              </div>
            }
          />

          <AccordionElement
            title="How to pay?"
            content={
              <div>
                <p>In Morocco, like most countries in the world, you can pay by cash or credit card. When paying by credit card, the exchange rate applied is the current rate. The maximum commission depends on each issuing institution, it is generally 1%.</p>
              </div>
            }
          />

          <AccordionElement
            title="Where to change currencies?"
            content={
              <div>
                <p>In establishments that do not give the possibility to pay by credit card, it is preferable to pay in Moroccan dirhams. The best places to change your currency are the exchange offices that are located in the tourist squares of each city in Morocco. You can change your currency at the airport or in hotels as well.</p>
              </div>
            }
          />

          <AccordionElement
            title="Language"
            content={
              <div>
                <p>Morocco has two official languages, Arabic and Amazigh. French is also spoken and understood by almost all Moroccans. English is more spoken and understood by a large number of people in Marrakech.</p>
              </div>
            }
          />

          <AccordionElement
            title="The U.S embassy and Consulate in Morocco"
            content={
              <div>
                <h3 className="text-2xl">U.S EMBASSY IN Morocco :</h3>
                <p><strong className="text-xl">Address:</strong> Km 5,7, Avenue Mohamed VI Souissi, Rabat 10170, Morocco</p>
                <p><strong className="text-xl">Phone:</strong> +(212) (0)537 637 200  / Fax: +(212) (0)537 637 201</p>

                <h3 className="text-2xl mt-4">U.S Consulate in Morocco :</h3>
                <p><strong className="text-xl">Address:</strong> 8, Bd Moulay Youssef Casablanca</p>
                <p><strong className="text-xl">Phone:</strong> +(212) (0)522 642 000  / Fax: +(212) (0)522 204 127</p>
              </div>
            }
          />

          <AccordionElement
            title="Visit Morocco"
            content={
              <div>
                <p>We can help you book your pre-event and/or post-event tours in Morocco, feel free to contact us at <button className="text-blue-600 cursor-pointer p-0 bg-transparent border-none" onClick={() => window.location.href = "mailto:contact@keventsmaroc.com"}>contact@keventsmaroc.com</button></p>
              </div>
            }
          />
        </Accordion>
      </div>

      <div className="bg-white">
        <div className="container max-w-6xl mx-auto px-4 py-20">
          <h2 className="text-5xl text-center mb-8">Contact us</h2>
          <div>
            <p className="text-xl">If you need more information, please contact us:</p>
            <p className="text-xl"><strong>Whatsapp number:</strong> <button onClick={() => window.location.href = "https://wa.me/+212661113430"}>+212661113430</button></p>
            <p className="text-xl"><strong>Email address:</strong> <button onClick={() => window.location.href = "mailto:contact@afimarrakech2024.com"}>contact@afimarrakech2024.com</button></p>
          </div>
        </div>
      </div>
    </>
  )
}
