import { Carousel } from "react-responsive-carousel"
import useFormStore from "../../features/form"
import Input from "../ui/Input"
import Select from "../ui/Select"
import { useEffect, useState } from "react"
import activityCapacity, { ActivityCapacityResponse } from "../../api/activityCapacity"

export default function FormActivities() {

  const { data, setData, errors } = useFormStore()

  const [activityChoices, setActivityChoices] = useState<string[]>([])

  useEffect(() => {
    activityCapacity().then(res => {
      const activities = ["No activity", "Guided tour of Marrakech ($70 / 700,00 MAD)"]

      const golfAvailable = res.find((res: ActivityCapacityResponse) => res.activity_name === "Golf tournament")?.is_available || false

      if (golfAvailable) {
        activities.push("Golf tournament ($280 / 2800,00 MAD)")
      }

      setActivityChoices(activities)
    }).catch(() => {
      setActivityChoices(["No activity", "Golf tournament ($280 / 2800,00 MAD)", "Guided tour of Marrakech ($70 / 700,00 MAD)"])
    })
  }, [])

  return (
    <div>
      <h2 className="text-3xl text-center font-bold mb-10 mt-3">Select an activity</h2>

      <div className="grid grid-cols-1 gap-4 mb-8">
        <Select
          id="activity"
          label="Activity*"
          choices={activityChoices}
          value={data.activity}
          onChange={(e) => setData("activity", e.target.value)}
          error={errors.activity}
        />
      </div>

      {data.activity.includes("Golf tournament") && (
        <div>
          <Carousel infiniteLoop showThumbs={false} swipeable emulateTouch>
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/golf_1.jpg")} />
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/golf_2.jpg")} />
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/golf_3.jpg")} />
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/golf_4.png")} />
          </Carousel>

          <div className="mt-8 mb-8">
            <p>We greatly appreciate your interest in our upcoming golf tournament. Your participation is crucial in making this event a success! To ensure your experience aligns with your preferences, we kindly request your valuable input by completing this brief questionnaire.</p>
            <p>Your responses will help us tailor the tournament to meet your needs and expectations.</p>
          </div>

          <div className="grid grid-cols-1 gap-4 mb-8">
            <Input
              id="handicap_index"
              label="Could you please provide your golf handicap index?"
              type="number"
              value={data.handicap_index}
              onChange={(e) => {setData("handicap_index", e.target.value), document.body.style.overflow= "hidden"}}
              onBlur={() => {
                document.body.style.overflow= ""
              }}
              error={errors.handicap_index}
            />

            <Select
              id="gender"
              label="Would you mind specifying your gender, please?"
              choices={["Female", "Male"]}
              value={data.gender}
              onChange={(e) => setData("gender", e.target.value)}
              error={errors.gender}
            />

            <Select
              id="own_golf_equipment"
              label="Do you bring your own golf equipment?"
              choices={["Yes", "No"]}
              value={data.own_golf_equipment}
              onChange={(e) => setData("own_golf_equipment", e.target.value)}
              error={errors.own_golf_equipment}
            />

            <Select
              id="golf_bag"
              label="Do you want to rent a golf bag?"
              choices={["Yes", "No"]}
              value={data.golf_bag}
              onChange={(e) => setData("golf_bag", e.target.value)}
              error={errors.golf_bag}
            />

            <Select
              id="handed"
              label="Are you a left-handed or right-handed player?"
              choices={["Left handed", "Right handed"]}
              value={data.handed}
              onChange={(e) => setData("handed", e.target.value)}
              error={errors.handed}
            />

            <Select
              id="gloves"
              label="Would you require gloves to be provided?"
              choices={["Yes", "No"]}
              value={data.gloves}
              onChange={(e) => setData("gloves", e.target.value)}
              error={errors.gloves}
            />

            <Select
              id="golf_transport"
              label="Would you prefer to play while walking with a manual trolley, using a golf cart alone or with a caddy?"
              choices={["Walking with a manual trolley", "Using golf cart alone", "Using golf cart with a caddy"]}
              value={data.golf_transport}
              onChange={(e) => setData("golf_transport", e.target.value)}
              error={errors.golf_transport}
            />
          </div>
        </div>
      )}

      {data.activity.includes("Guided tour of Marrakech") && (
        <div>
          <Carousel infiniteLoop showThumbs={false} swipeable emulateTouch>
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/tour_1.jpg")} />
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/tour_2.jpg")} />
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/tour_3.jpg")} />
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/tour_4.jpg")} />
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/tour_5.jpg")} />
            <img className="w-full h-[300px] object-cover rounded-t-xl" src={require("../../assets/tour_6.jpg")} />
          </Carousel>

          <div className="mt-8 mb-4">
            <p>Spend half a day exploring Marrakech City with a professional guide. Attendees would explore the attractions of Marrakesh on a private tour. Attendees will visit Koutoubia Mosque and Minaret, and see other landmarks that symbolize the history and culture of the city :</p>
            <ol className="mt-8 flex flex-col gap-4">
              <li><strong>Majorelle Garden & Yves Saint Laurent Museum:</strong> Savor the beautiful botanical gardens of Majorelle, this small tropical garden laid out in the inter-war period by a French artist.<br />Yves Saint Laurent Museum is a museum dedicated to the fashion designer Yves Saint Laurent.</li>
              <li><strong>Koutoubia Mosque:</strong> Koutoubia is undoubtedly one of the magnificent structures in Marrakech, and also it is considered as one of the fascinating mosques of the Western Muslim World. Historically, it is a dual-purpose sanctuary combined with a minaret.</li>
              <li><strong>Jemma El Fna Square:</strong> Discover the colorful wonders of Jemaa el Fnaa medina. Immerse yourself in the medina's vibrant atmosphere, learn about its history, shop for trinkets and souvenirs, and taste local delicacies and traditional teas.</li>
            </ol>
          </div>
        </div>
      )}
    </div>
  )
}
