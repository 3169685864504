import request from "../config/request"

export type ReservationRequest = {
  id: string
}

export type ReservationResponse = {
  id: number
  created_at: string
  updated_at: string
  last_name: string
  first_name: string
  date_birth: string
  email: string
  phone: string
  reservation_start_date: string
  reservation_end_date: string
  passport: string
  type_room: string
  transfert: null
  arrival_airport: string
  arrival_date: string
  arrival_time: string
  arrival_flight_number: string
  departure_airport: string
  departure_date: string
  departure_time: string
  departure_flight_number: string
  handicap_index: string
  gender: string
  own_golf_equipment: string
  golf_bag: string
  handed: string
  gloves: string
  golf_transport: string
  price: string
  validated: boolean | null
  hotel: string
  activity: string
  amountCur: string
  symbolCur: string
}

export type ReservationError = {
  detail: string
}

export default async function reservation(params: ReservationRequest) {
  const response = await request({
    method: "GET",
    url: "/reservation/reservation-detail/" + params.id + "/",
  })

  return response.data as ReservationResponse
}
